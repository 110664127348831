/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
  font-family: Montserrat, Roboto, Helvetica Neue Light, Helvetica Neue, Arial, Lucida Grande, sans-serif;
  font-style: normal;
  color: #73818f !important;
  overflow: hidden;
  margin: 0px;
}

@media screen and (max-width: 1400px){
  body{
    font-size: 11px;
  }

  .mat-card-content {
    font-size: 11px !important;
    white-space: nowrap;
  }
}

@media screen and (min-width: 1400px){
  body{
    font-size: 13px;
  }

  .mat-card-content {
    font-size: 13px !important;
    white-space: nowrap;
  }
}

input, textarea, select, button {
  font-size: 11px;
}

.conteudo {
  overflow: auto;
  margin-top: -4px;
  padding-left: 8px;
  padding-right: 8px;
}

.mat-card-header-text {
  margin: 4px 8px !important;
}

.mat-card-subtitle {
  color: #767676 !important;
  font-size: 14px !important;
  padding-bottom: 4px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0,0,0,.12);
}

.mat-card {
  margin: 0px 4px 4px 4px;
}



.button-xs {
  min-width: 20px !important;
}

.button-xxs {
  min-width: 20px !important;
  padding: 0px 8px !important;
  line-height: 26px !important;
}

.button-grid {
  min-width: 20px !important;
  padding: 0px 8px !important;
  line-height: 26px !important;
}

.button-gray {
  color: #767676 !important;
  font-weight: normal !important;
  font-size: 11px !important;
}

.button-green{
  background-color:  #3cb371 !important;
  color: white !important;
}

.mat-tab-label {
  height: 36px !important;
  font-size: 14px !important;
  font-weight: normal !important;
  min-width: 120px !important;
  text-align: left !important;
  padding: 0 12px !important;
  justify-content: start !important;
}

.mat-tab-label-active {
  color: #3f51b5 !important;
}

.mat-dialog-actions {
  flex-direction: row-reverse;
}

  .mat-dialog-actions > button {
    margin-left: 12px;
  }

.mat-form-field-full > * {
  width: 100%;
}

.mat-toolbar.mat-primary {
  color: #73818f !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

.mat-drawer-container {
  color: #474547 !important;
}

.mat-menu-item {
  font: 400 14px/20px Montserrat, Roboto,"Helvetica Neue",sans-serif;
  height: unset !important;
  color: #474547 !important;
}

.ng-fa-icon {
  padding-left: 4px;
  padding-right: 4px;
  color: #767676;
}

.ng-fa-icon.white {
  color: white;
}

.mat-button-toggle-label-content {
  line-height: 32px !important;
}
.mat-radio-button {
  padding-right: 16px !important;
}

.color-serie-tooltip {
  display: inline-flex;
  margin-right: 4px;
  width: 10px;
  height: 10px;
  border: solid 1px #efefef;
}

.dx-overlay-wrapper {
  font-size: 11px !important;
}

.dx-texteditor-input {
  font-size: 11px !important;
}

.dx-placeholder {
  font-size: inherit !important;
}

.dx-field {
  margin-top: -9px !important;
}

.dx-field-title {
  font-size: 10px;
  color: #7f7f7f;
  position: absolute;
  top: 10px;
}

.dx-span-title {
  font-size: 10px;
  color: #7f7f7f;
}

.dx-datagrid-content .dx-datagrid-table {
  border-collapse: unset !important;
}
  .dx-datagrid .dx-row > td {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  font-size: 11px !important;
}

.dx-list-item-content {
  font-size: inherit !important;
  padding: 11px 15px !important;
}

.dx-show-clear-button .dx-icon-clear {
  width: auto !important;
}
  .dx-popup-title.dx-toolbar {
  padding: 0 8px 0 16px !important;
}

  .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
    height: 48px !important;
  }

  .dx-popup-title.dx-toolbar .dx-toolbar-label {
    font-size: 16px !important;
  }


  .cdk-overlay-container {
    z-index: 1600 !important;
}